import React from "react"
import { Helmet } from "react-helmet"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../../styles/style.css"
import ttm from "../../../static/images/icons/web/icon18.svg"
import costes from "../../../static/images/icons/web/icon16.svg"
import calidad from "../../../static/images/icons/web/icon17.svg"

const Metodologia = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "cabecera-qualoom.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Especialistas en Metodología DevOps para Software | Qualoom</title>
        <meta name="title" content="Especialistas en Metodología DevOps para Software | Qualoom"/>
        <meta name="description" content="En Qualoom ofrecemos servicios informáticos siguiendo la Metodología DevOps para la creación de Software. Consúltanos sin compromiso e Infórmate ¡YA!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/devops/metodologia/"/>
        <link rel="canonical" href="https://www.qualoom.es/devops/metodologia/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/devops/metodologia/"/>
        <meta property="og:title" content="Especialistas en Metodología DevOps para Software | Qualoom"/>
        <meta property="og:description" content="En Qualoom ofrecemos servicios informáticos siguiendo la Metodología DevOps para la creación de Software. Consúltanos sin compromiso e Infórmate ¡YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/devops/metodologia/"/>
        <meta property="twitter:title" content="Especialistas en Metodología DevOps para Software | Qualoom"/>
        <meta property="twitter:description" content="En Qualoom ofrecemos servicios informáticos siguiendo la Metodología DevOps para la creación de Software. Consúltanos sin compromiso e Infórmate ¡YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qEkBiWp6XWYzJYweuZVSK/00681335572a0fe04ef29bc8dba9096b/tw_cards_servicios.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/devops/metodologia/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"DevOps", "item": {"@id": "https://www.qualoom.es/devops/", "name": "DevOps"}},
              {"@type":"ListItem", "position":"3", "name":"Metodología DevOps", "item": {"@id": "https://www.qualoom.es/devops/metodologia/", "name": "Metodología DevOps"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Gestión del rendimiento con la metodología DevOps</h1>
              <p>Uno de los principales factores que se ven mejorados con la implementación de esta metodología es el rendimiento, el cual es posible medir en las diferentes etapas de la producción de artefactos software, desde la etapa de desarrollo, entrega, pruebas y verificación hasta la puesta en producción.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué es la metodología DevOps?</h2><hr></hr>
          <div className="section-main">
            <div className="section-img devops"></div>
            <div className="section-text">
              <p>DevOps, conjunto de prácticas, métodos y herramientas que mejoran el marco colaborativo entre las áreas de operaciones, desarrollo, calidad y sistemas con el fin de reducir el time-to-market de nuevos productos y servicios, mejorar la calidad de los mismos y reducir los costes productivos de manera continuada.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>Proporciona a tu negocio una metodología ágil</h2><hr></hr>
          <p>La implementación de esta metodología reforzará las distintas áreas de tu empresa:</p>
          <ul>
            <li>El <b>equipo de operaciones y negocio</b> tendrá una mayor visibilidad y trazabilidad de los tiempos de entrega, etapas de producción y su calidad, creando un ecosistema más adecuado para la toma de decisiones estratégicas.</li>
            <li>Los <b>equipos de desarrollo</b>, ya sean inhouse o remotos, conseguirán poner a disposición del resto de equipos sus entregables de una manera más rápida, automatizada y controlada, allanando el camino al equipo de Q&A y estando preparados para la recepción de incidencias y su posterior corrección.</li>
            <li>La etapa de calidad, liderada por el <b>equipo de Q&A</b>, será automatizada, trazable y continúa permitiendo a este equipo enfocarse en lo realmente importante, el aseguramiento de la calidad de servicios y productos y transmitiendo la información al equipo de desarrollo en caso de anomalías.</li>
            <li>El <b>equipo de sistemas</b> será el encargado de dar soporte con herramientas y procedimientos al resto de áreas para conseguir este enfoque ágil de producción.</li>
          </ul><br/>
          <h3>¿Por qué tu negocio necesita seguir la metodología DevOps?</h3>
          <p>Sencillo y de sentido común. Es la metodología que da respuesta a cada una de las áreas en función de sus competencias y necesidades productivas de manera que a través de la interconexión de todas ellas, se pueda verificar que el proceso es medible, mejorable y sobre todo estable.</p><br/>
          <h3>Las ventajas de una metodología DevOps ágil</h3>
          <div  className="advantages">
            <div className="advantages-items">
              <div><img src={ttm} alt="Time-to-market"/></div>
              <h3>Time-to-market</h3>
              <p>La automatización de los complejos flujos de trabajo  entre los entornos de trabajo acelera y simplifica los tiempos de desarrollo de software.</p>
            </div>
            <div className="advantages-items">
              <div><img src={costes} alt="Reducción de costes"/></div>
              <h3>Reducción de costes</h3>
              <p>El aumento de la eficacia de los trabajadores, la reducción del tiempo necesario para la entrega de un producto final y la agilidad en el proceso contribuyen directamente a una considerable reducción del coste.</p>
            </div>
            <div className="advantages-items">
              <div><img src={calidad} alt="Calidad del producto"/></div>
              <h3>Aseguramiento de la calidad</h3>
              <p>Con esta metodología se verifica continuamente el estado del software desde las primeras etapas de su creación, pudiendo resolver las posibles incidencias con mayor rapidez y garantizando la calidad del producto.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿En qué te puede ayudar Qualoom?</h2><hr></hr>
          <p>A través de la utilización de herramientas que posibiliten la implementación de esta metodología de trabajo entre departamentos de TI, producto, desarrollo y negocio, nuestro equipo de ingeniería pone a disposición conocimiento y experiencia para mejorar la calidad de los productos generados y reducir los costes productivos de manera continuada.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Metodologia
